import React from "react"
import Layout from "../components/layout.js"
import Contact from "../components/contact.js"
import Container from '@material-ui/core/Container';


const ContactPage = () => (
    <Layout>
      <Contact></Contact>
    </Layout>
  )
  
export default ContactPage